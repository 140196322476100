export const getSitecoreGraphQLEndpoint = (hostname: string) => {
  return `${hostname}/sitecore/api/authoring/graphql/v1`;
};

export const getPagesGraphQLEndpoint = (hostname: string) => {
  return `${hostname}/sitecore/api/ssc/horizon/query/?sc_horizon=api&sc_headless_mode=api`;
};

export function getLocalAuthoringUrl() {
  return 'https://xmcloudcm.localhost';
}
