import { useToast } from '@chakra-ui/react';
import { AxiosResponse } from 'axios';
import { useMemo } from 'react';
import { UseQueryOptions } from 'react-query';
import { useAuthQuery } from 'src/features/common/apiUtils/queryHelper';
import { getPagesGraphQLEndpoint } from 'src/features/common/config';
import { GraphQLResponse, WorkflowAccessRightsResponse } from 'src/features/common/models';
import { useXMTenant } from 'src/features/common/tenants/xmTenantContext';
import { workflow } from 'src/features/workflow/graphql/workflow';

export const useGetWorkflowAccessRights = (
  itemId: string,
  language: string,
  site: string,
  useQueryOptions?:
    | Omit<
        UseQueryOptions<
          AxiosResponse<GraphQLResponse<WorkflowAccessRightsResponse>>,
          unknown,
          AxiosResponse<GraphQLResponse<WorkflowAccessRightsResponse>>,
          string[]
        >,
        'queryKey' | 'queryFn'
      >
    | undefined,
) => {
  const { url } = useXMTenant();
  const toast = useToast();

  const { data, isFetching } = useAuthQuery(
    ['get-workflow-access-rights', itemId, language, site],
    async (axiosInstance) => {
      return axiosInstance?.post<GraphQLResponse<WorkflowAccessRightsResponse>>(
        getPagesGraphQLEndpoint(url),
        workflow.get_workflow_access_rights(itemId, language, site),
      );
    },
    { ...useQueryOptions },
    toast,
  );

  const canExecuteWorkflowActions = useMemo(() => data?.data?.data.item.workflow.canEdit, [data]);

  const availableActions = useMemo(() => data?.data?.data.item.workflow.commands.map((cmd) => cmd.id), [data]);

  return { canExecuteWorkflowActions, availableActions, isFetching };
};
