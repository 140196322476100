import { AxiosResponse } from 'axios';
import { memo, useCallback, useMemo } from 'react';
import { useFeatures } from 'src/features/common/flags/features';
import { UrlParams } from 'src/features/common/hooks/useUrlSharing';
import { useUrlStrictContext } from 'src/features/common/hooks/useUrlStrictContext';
import {
  WorkflowCommand as Command,
  DetailedItem,
  ExecuteWorkflowCommandInput,
  GraphQLResponse,
  WorkflowResponse,
} from 'src/features/common/models';
import { isSameGuid } from 'src/features/common/utils/guidUpdater';
import { useGetWorkflowAccessRights } from 'src/features/workflow/hooks/useGetWorkflowAccessRights';
import { useGetWorkflowCommands } from 'src/features/workflow/hooks/useGetWorkflowCommands';
import { useUpdateItemWorkflow } from 'src/features/workflow/hooks/useUpdateItemWorkflow';
import { WorkflowCommand } from './WorkflowCommand';

export interface WorkflowCommandsProps {
  item: DetailedItem;
  language: string;
  onWorkflowUpdate: () => void;
}

const formatCommands = (
  commands: AxiosResponse<GraphQLResponse<WorkflowResponse>>,
  availableActions: string[] | undefined,
  xm_workflow_access_rights: boolean,
): Command[] => {
  return commands?.data.data.workflow.commands.nodes.filter((f) =>
    !f.displayName.startsWith('__') && xm_workflow_access_rights
      ? availableActions?.some((action) => isSameGuid(action, f.commandId))
      : true,
  );
};

export const WorkflowCommandsContainer = memo(({ item, language, onWorkflowUpdate }: WorkflowCommandsProps) => {
  const { sc_site } = useUrlStrictContext(UrlParams.sc_site);
  const { xm_workflow_access_rights } = useFeatures();
  const { mutate: updateItemWorkflow } = useUpdateItemWorkflow();
  const { data: commands, isSuccess: workflowIsSuccess } = useGetWorkflowCommands(
    item.itemId,
    language,
    item.workflow?.workflow?.workflowId || '',
    item.workflow?.workflowState?.stateId || '',
    {
      enabled: !!item,
    },
  );

  const { canExecuteWorkflowActions, availableActions } = useGetWorkflowAccessRights(item.itemId, language, sc_site, {
    enabled: xm_workflow_access_rights,
  });

  const itemCommands: Command[] | undefined = useMemo(
    () => commands && formatCommands(commands, availableActions, xm_workflow_access_rights),
    [commands, availableActions, xm_workflow_access_rights],
  );

  const onCommandClick = useCallback(
    (commandId: string, comments: string) => {
      const input = { commandId, comments, item: { itemId: item.itemId, language, version: item.version } } as ExecuteWorkflowCommandInput;
      updateItemWorkflow(input, { onSuccess: onWorkflowUpdate });
    },
    [item, language, onWorkflowUpdate, updateItemWorkflow],
  );

  return (
    <>
      {workflowIsSuccess &&
        (xm_workflow_access_rights ? canExecuteWorkflowActions : true) &&
        itemCommands?.map((command) => <WorkflowCommand key={command.commandId} command={command} onCommandClick={onCommandClick} />)}
    </>
  );
});

WorkflowCommandsContainer.displayName = WorkflowCommandsContainer.name;
